import React, { useEffect, PropsWithChildren, useMemo } from 'react';
import SearchInsights from 'search-insights';

import { analytics, isNonEmptyString } from '~/common';
import { useCurrentUser } from '~/src/common/user';

interface SearchInsightsContextType {
  algoliaToken: string;
  userClassLevel: string;
}

const gatsbyAppID = process.env.GATSBY_ALGOLIA_APP_ID || '';
const gatsbySearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY || '';
SearchInsights('init', {
  appId: gatsbyAppID,
  apiKey: gatsbySearchKey,
  useCookie: true,
});

export const SearchInsightsContext = React.createContext<SearchInsightsContextType>({
  algoliaToken: '',
  userClassLevel: '',
});

export const SearchInsightsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { currentUser, loading } = useCurrentUser();

  let userToken = '';
  let userClassLevel = '';

  if (!loading) {
    if (!currentUser) {
      const anonymousId = analytics.getAnonymousId();
      if (anonymousId) {
        userToken = anonymousId;
      }
    } else {
      const classLevel = currentUser.class_level?.[0]?.name;
      if (isNonEmptyString(classLevel)) {
        userClassLevel = classLevel;
      }

      userToken = currentUser.id;
    }
  }

  useEffect(() => {
    if (isNonEmptyString(userToken)) {
      SearchInsights('setUserToken', userToken);
    }
  }, [userToken]);

  const value = useMemo(() => {
    return {
      algoliaToken: userToken,
      userClassLevel,
    };
  }, [userClassLevel, userToken]);

  return <SearchInsightsContext.Provider value={value}>{children}</SearchInsightsContext.Provider>;
};
