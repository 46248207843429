import { useEffect } from 'react';
import { useQuery } from '@apollo/client/react/hooks';
import { gql } from '@yi/core';

import {
  notifyBugsnag,
  makeFeatureFlagLocalStorageKey,
  getStorage,
  FeatureFlagName,
} from '~/common';

import type { QueryHookOptions } from '@apollo/client/react';

const storage = getStorage();

const getLocalOverride = (name: FeatureFlagName): null | true | false => {
  try {
    const value = storage.getItem(makeFeatureFlagLocalStorageKey(name));
    if (typeof value !== 'boolean') return null;
    return value;
  } catch (e) {
    return null;
  }
};

export const useFeatureFlag = (
  name: FeatureFlagName,
  opts?: QueryHookOptions<
    gql.typesClient.Query_FeatureFlag,
    gql.typesClient.Query_FeatureFlagVariables
  >,
) => {
  const localOverride = getLocalOverride(name);
  const hasLocalOverride = typeof localOverride === 'boolean';

  useEffect(() => {
    if (hasLocalOverride) {
      console.warn(
        `Using local override for feature flag: ${name}=${localOverride ? 'on' : 'off'}`,
      );
    }
  }, [hasLocalOverride, name, localOverride]);

  const { data, ...rest } = useQuery<
    gql.typesClient.Query_FeatureFlag,
    gql.typesClient.Query_FeatureFlagVariables
  >(gql.Query_FeatureFlag, {
    variables: { name },
    ssr: false,
    skip: hasLocalOverride,
    fetchPolicy: 'cache-first',
    onError: notifyBugsnag,
    ...opts,
  });

  const enabled: boolean = hasLocalOverride ? !!localOverride : !!data?.FeatureFlag.enabled;
  return { enabled, ...rest };
};
