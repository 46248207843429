import { BrooklynJwtValidation } from '~/src/components/BrooklynJwtValidation';
import { CaptureUTM } from '~/src/components/CaptureUTM';
import { EntitlementsValidation } from '~/src/components/EntitlementsValidation';
import { FeatureFlagCacheWarming } from '~/src/components/side-effects/FeatureFlagCacheWarming';
import { SyncBugsnagMetadata } from '~/src/components/side-effects/SyncBugsnagMetadata';

export const GatsbyBrowserRootElementSideEffects: React.FC = () => {
  return (
    <>
      <BrooklynJwtValidation />
      <CaptureUTM />
      <EntitlementsValidation />
      <SyncBugsnagMetadata />
      <FeatureFlagCacheWarming />
    </>
  );
};
