import Cookies from 'js-cookie';
import { gql, TSFixMe, ReplaceProps, gaia } from '@yi/core';
import { MembershipPlanInterval } from '@yi/core/src/gql/generated/typesClient';

import * as utilities from '../utilities';

const SSR = utilities.SSR;

export interface RegularEvent {
  event:
    | 'annualUpgrade'
    | 'logoutStateChange'
    | 'logoutButtonClick'
    | 'login'
    | 'emailCapture'
    | 'accountCreation'
    | 'liveRegistration'
    | 'test/LiveRegistration'
    | 'liveCancelation'
    | 'test/LiveCancelation';
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventNonInteraction: boolean;
  email?: string;
  category: string;
  label: string;
  cancelationToken?: string;
  automationToken?: string;
  timeUntil?: string;
  title?: string;
  teacher?: string;
  meetingUrl?: string;
  startDate?: string;
  startTime?: string;
}

export interface ProductEvent {
  event:
    | 'membershipSignup'
    | 'test/membershipSignup'
    | 'changedPlan'
    | 'checkout'
    | 'paymentInfo'
    | 'coursePurchase'
    | 'addToCart';
  productType: string;
  productName: string | null;
  productId: string | null;
  productPrice: number | string | null;
  [k: string]: TSFixMe | undefined;
}

export interface SimpleEvent {
  event:
    | 'memberOfferHit'
    | 'gatsby-pageview'
    | 'addToCalendar'
    | 'experimentViewed'
    | 'exitedPracticeParty';
  [k: string]: TSFixMe | undefined;
}

export type AnalyticsEvent = ProductEvent | SimpleEvent | RegularEvent;

export type Offer = gql.typesSsr.Query_MemberPromo_yi_MemberPromo_offer;

export type Course = Pick<gql.typesSsr.Query_Course_yi_Course, 'title' | 'id' | 'a_la_carte_price'>;

export type Bundle = ReplaceProps<
  Pick<
    gql.typesSsr.Query_CourseBundle_yi_CourseBundle,
    'title' | 'id' | 'courses' | 'discount_percent'
  >,
  { courses: Course[] }
>;

export type Product = { offer: Offer } | { bundle: Bundle } | { course: Course };

type MembershipTier = gql.typesClient.MembershipTier;
const { Digital, Premium } = gql.typesClient.MembershipTier;
const { YEAR, MONTH } = gql.typesClient.MembershipPlanInterval;

export type CoursePurchaseEventProps = {
  order: gql.typesClient.Mutate_CartCheckout_cartCheckout_order;
  type: string;
  current_user?: gql.typesClient.Mutate_CartCheckout_cartCheckout_current_user | null;
  variables?: gql.typesClient.Mutate_CartCheckoutVariables;
  hasAddon: boolean;
  addons?: gql.typesSsr.Query_Course_CoursePurchasePage_yi_Course_addon[];
  bundle_id?: string;
  bundle_name?: string;
};

function getTierInfoFromGaiaPlanSku(offer: Pick<Offer, 'zuora_plan_sku'> | null) {
  const zuora_plan_sku = offer === null ? null : offer.zuora_plan_sku;
  const plan = zuora_plan_sku ? gaia.getMembershipPlanFromSku(zuora_plan_sku) : null;
  const offer_tier = plan?.tier || Digital;
  const offer_interval = plan?.interval || MONTH;
  const offer_value = getEstimatedOfferValue(offer_tier, offer_interval);
  const productName = `${offer_tier} ${offer_interval} Membership`;
  const productId = `${offer_tier}${offer_interval}`;
  const products = [
    getProductProperties(productId, productName, offer_value, { offer_interval, offer_tier }),
  ];
  return {
    offer_tier,
    offer_interval,
    offer_value,
    productName,
    productId,
    productPrice: offer_value,
    productType: 'membership',
    products,
  };
}

export function getTierInfoFromOffer(args: Pick<Offer, 'zuora_plan_sku'> | null) {
  return getTierInfoFromGaiaPlanSku(args);
}

export function getProductPropsFromCourse(course: Course) {
  const productName = course.title;
  const productId = course.id;
  const productPrice = `${utilities.getCoursePrice(course)}`;
  const products = [getProductProperties(productId, productName, productPrice, {})];
  return {
    productName,
    productId,
    productPrice,
    productType: 'course',
    products,
  };
}

export function getProductPropsFromBundle(bundle: Bundle) {
  const productName = bundle.title;
  const productId = bundle.id;
  const productPrice = `${utilities.getCourseBundlePrice(bundle, true)}`;
  const products = bundle.courses.map(course_ =>
    getProductProperties(
      course_.id,
      course_.title,
      `${utilities.getSingleCourseBundlePrice(course_, bundle, true)}`,
      {},
    ),
  );

  return {
    productName,
    productId,
    productPrice,
    productType: 'bundle',
    products,
  };
}

function getEstimatedOfferValue(
  offer_tier: MembershipTier,
  offer_interval: MembershipPlanInterval,
) {
  if (offer_tier === Premium) {
    return '490';
  }
  if (offer_tier === Digital && offer_interval === YEAR) {
    return '169';
  }
  return '150';
}

export function getProductProperties(
  id: string,
  name: string,
  price: string,
  additionalProperties: { [k: string]: undefined | string },
) {
  return {
    id,
    name,
    price,
    product_id: id,
    quantity: 1,
    sku: id,
    ...additionalProperties,
  };
}

export function parseProductData(event: ProductEvent) {
  const {
    productId: product_id,
    productId: sku,
    productType: category,
    productName: name,
    productPrice: price,
    productPrice: value,
    productPrice: revenue,
  } = event;

  return { product_id, sku, category, name, price, value, revenue, currency: 'USD' };
}

export function getProductProps(product: Product) {
  if ('offer' in product) return getTierInfoFromGaiaPlanSku(product.offer);
  if ('course' in product) return getProductPropsFromCourse(product.course);
  if ('bundle' in product) return getProductPropsFromBundle(product.bundle);

  return {
    products: [],
    productType: '',
    productName: '',
    productId: '',
    productPrice: '',
  };
}

export function getCourseProductsFromOrder(
  order: gql.typesClient.Mutate_CartCheckout_cartCheckout_order,
) {
  return Array.isArray(order.items)
    ? order.items.map(item => ({
        category: 'eCourse',
        id: item.id,
        name: item.title,
        price: item.price,
        product_id: item.id,
        quantity: 1,
        sku: item.id,
      }))
    : [];
}

export function getShipstationProperties(
  order: gql.typesClient.Mutate_CartCheckout_cartCheckout_order,
  current_user: gql.typesClient.Mutate_CartCheckout_cartCheckout_current_user | null,
  variables: gql.typesClient.Mutate_CartCheckoutVariables,
  addons: gql.typesSsr.Query_Course_CoursePurchasePage_yi_Course_addon[],
) {
  const date = new Date()
    .toISOString()
    .replace('T', ' ')
    .replace('Z', '')
    .replace(/\.\d+$/, '');

  const shipmentItems = Array.isArray(order.items)
    ? order.items.map(item => {
        const addon = addons.find(courseAddon => courseAddon.id === item.id);

        return {
          lineItemKey: item.id,
          sku: addon ? addon.sku : null,
          name: item.title,
          imageUrl: null,
          weight: {
            value: addon ? addon.weight : 0,
            units: 'ounces',
          },
          quantity: 1,
          unitPrice: item.price,
        };
      })
    : [];

  return {
    orderNumber: order.title.replace('Order #', ''),
    orderKey: order.id,
    orderDate: date,
    orderStatus: 'awaiting_shipment',
    customerEmail: current_user ? current_user.email : '',
    billTo: {
      name: `${variables.first_name} ${variables.last_name}`,
      company: null,
      street1: variables.address,
      street2: variables.address2,
      street3: null,
      city: variables.city,
      state: variables.state,
      postalCode: variables.zip,
      country: variables.country_code,
      phone: variables.phone,
      residential: true,
    },
    shipTo: {
      name: `${variables.first_name} ${variables.last_name}`,
      company: null,
      street1: variables.address,
      street2: variables.address2,
      street3: null,
      city: variables.city,
      state: variables.state,
      postalCode: variables.zip,
      country: variables.country_code,
      phone: variables.phone,
      residential: true,
    },
    items: shipmentItems,
    amountPaid: order.total,
    taxAmount: 0,
    shippingAmount: 0,
    carrierCode: null,
    serviceCode: null,
    requestedShippingService: null,
  };
}

export function getAnonymousId() {
  let anonymousId;

  const ajs_anonymous_id_cookie = Cookies.get('ajs_anonymous_id');

  if (ajs_anonymous_id_cookie) {
    // remove quotes from cookie
    anonymousId = ajs_anonymous_id_cookie.replace(/['"]+/g, '');
  }

  if (!anonymousId && window.analytics) {
    window.analytics.ready(() => {
      anonymousId = window.analytics.user().anonymousId();
    });
  }

  return anonymousId;
}

const missingAnalytics = {
  track: () => undefined,
  identify: () => undefined,
  page: () => undefined,
  reset: () => undefined,
};

export const getAnalytics = () => {
  if (SSR) {
    return {
      analytics: missingAnalytics,
      anonymousId: '',
      segmentData: undefined,
      simpleUserTraits: {},
      simpleUserTraitsWithIntegrations: {},
    };
  }

  const anonymousId = getAnonymousId();
  const simpleUserTraits = window.segmentData
    ? {
        traits: {
          email: window.segmentData.email || undefined,
          firstName: window.segmentData.firstName || undefined,
          lastName: window.segmentData.lastName || undefined,
          phone: window.segmentData.phone || undefined,
        },
      }
    : undefined;

  const simpleUserTraitsWithIntegrations = {
    integrations: { Optimizely: { userId: anonymousId } },
    context: { ...simpleUserTraits },
  };

  return {
    analytics: window.analytics,
    segmentData: window.segmentData,
    anonymousId,
    simpleUserTraits,
    simpleUserTraitsWithIntegrations,
  };
};
